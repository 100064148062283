export enum CbhFeatureFlag {
  ATTENDANCE_SCORE_POLICY_LINKS = "attendance-score-policy-links",
  AUTO_VERIFICATION_DOCUMENT_CLEANUP_ENABLED = "auto-id-deletes-abandoned-documents",
  CAMERA_IMAGE_QUALITY = "camera-image-quality-config",
  CAMERA_IMAGE_SIZE = "camera-image-size-config",
  CONVERT_TO_NONIP_AFTER_X_MINUTES = "convert-to-nonIP-after-x-minutes",
  DOCUMENT_VERIFICATION_PROCESS = "document-verification-process",
  ENABLE_CHECKR_PILL_STATUS = "enable-checkr-pill-status",
  ENABLE_GEOLOCATION_TRACKING_FOR_SHIFTS = "enable-geolocation-tracking-for-shifts",
  ENABLE_LATE_FOR_SHIFT = "enable-late-for-shift",
  ENABLE_PA_NOTIFICATION_PREFERENCE = "enable-pa-notification-preference",
  ENABLE_PROFESSIONAL_REFERENCES = "reference-web-form",
  ENABLE_REMAINING_SHIFT_DATA_IN_A_SLOT = "enable-remaining-shift-data-in-a-slot",
  ENABLE_TAG_LOCATION_POPUP = "enable-tag-location-modal",
  FACILITY_TYPE_SHIFT_ICONS = "facility-type-shift-icons-1",
  FORCE_UPDATE_ON_LOGIN = "force-update-on-login",
  HCP_APP_RATING_PROMPT_INTERVAL = "hcp-app-rating-prompt-interval",
  HCP_LICENSE_DEFAULT_STATUS = "hcp-license-default-status",
  HCP_LICENSE_FIELDS_REQUIREMENTS = "hcp-license-field-requirements",
  NLC_STATES = "nlc-states",
  ONBOARDING_STRIPE_SETUP_UNSKIPPABLE = "stripe-setup-unskippable",
  PERSONAL_ID_SUBTYPES = "personal-id-subtypes",
  RADAR_SDK_CONFIG_AGGRESSIVE_TRACKING = "radar-sdk-config-aggressive-tracking",
  RADAR_SDK_CONFIG_ONSITE_TRACKING = "radar-sdk-config-on-site-tracking",
  RATE_NEGOTIATION_ACTIVATION = "rate-negotiation-activation",
  RATE_NEGOTIATION_LEAD_TIME = "rate-negotiation-lead-time",
  RATE_NEGOTIATION_LIMITS = "rate-negotiation-limits",
  READS_FILE_STORAGE_MOBILE = "reads-file-storage-mobile",
  SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION = "send-bird-concurrent-connection-optimisation",
  SENT_HOME_REQUEST_CONFIG = "sent-home-request-config",
  SHIFTS_SORT_KEY_TIMESLOT = "shifts-sort-key-timeslot",
  SHOW_CANCELLATION_PAYOUT = "show-cancellation-payout",
  SUPPORT_LINKS = "support-links",
  TIMESHEETS_V2_NON_IP = "timesheets-v2-non-IP",
  URGENT_SHIFTS = "urgent-shifts",
  URGENT_SHIFTS_CONFIG = "urgent-shifts-config",
  USE_ORIGINALAMOUNT_FIELD_NON_IP = "use-originalamount-field-non-ip",
  WORK_WITH_FRIENDS_BONUSES = "work-with-friends-bonuses",
  OPEN_SHIFT_CARD_AGENT_REQ = "open-shift-card-agent-req",
  SEND_FCF_TO_URGENT_SHIFTS_FLOW = "send-fcf-to-urgent-shifts-flow",
  SIGNUP_BASED_REFERRAL = "signup-based-referral-bonus",
  ENABLE_REFERRAL_SIGNUP_SCREEN = "enable-referral-signup-screen",
  URGENT_SHIFTS_HOURLY_PAY_RATE = "urgent-shifts-hourly-pay-rate",
  ENABLE_REFERRAL_SUBMISSION_ONBOARDING_SCREEN = "enable-enter-referral-code-onboarding-screen",
  ENABLE_NO_FACILITY_MAP_ALERT = "enable-no-facility-map-alert",
  ENABLE_BROWSING_SHIFTS_ACROSS_US_MAP_VIEW = "enable-browsing-shifts-across-us-map-view",
  FACILITY_DISTANCE_THRESHOLD_MAP_VIEW_CONFIG = "facility-distance-threshold-map-view-config",
  SIGN_UP_REFERRAL_SCREEN_EXPIRATION_DAY = "sign-up-referral-screen-expiration-days",
  DISABLE_UX_CAM = "disable-ux-cam",
  SHOW_ONBOARDING_AGENT_PROFILE_ERROR_MODAL = "show-onboarding-agent-profile-error-modal",
  MAX_REFERRAL_BONUS_CONFIG = "maximum-referral-bonus-config",
  HCP_CAN_EDIT_DATE_FOR_MULTI_DAY_SHIFTS = "hcp-can-edit-date-for-multi-day-shifts",
  SHOW_SHIFT_STATUS_FOOTER_INFO = "show-shift-status-footer-info",
  ENABLE_LUNCH_BREAK_INFO_POPUP = "enable-lunch-break-popup",
  ENABLE_WORKER_AVAILABILITY = "enable-worker-availability",
  WORKER_AVAILABILITY_CALENDER_CONFIG = "availability-calendar-button-config",
  HCP_LICENSE_NUMBER_REQUIREMENTS = "hcp-license-number-requirements",
  RADAR_SDK_CONFIG = "radar-sdk-config",
  WORKPLACE_REFERRALS = "2023-10-facilitate-rollout-workplace-referrals",
  ENABLED_REFERRAL_PROGRAMS = "2023-10-sparta-enabled-referral-programs",
  SIGNUP_REFERRAL_BONUS_PAYMENT_ETA_IN_DAYS = "2023-12-sparta-signup-referral-bonus-payment-eta",
  ENABLED_SIGNUP_REFERRAL_GUARD_RAILS = "2023-12-sparta-enabled-sign-up-referral-guard-rails",
  UNVERIFIED_SHIFTS_V2_ENABLED = "2023-11-frontend-force-rollout-v2-route-unverified-shifts",
  WORKER_TO_WORKPLACE_AFFILIATE_EXPERIMENT = "2023-12-facilitate-experiment-worker-to-workplace-affiliate",
  MAX_DISTANCE_ALLOWED_FOR_LOCATION_VERIFICATION_IN_MILES = "2023-12-team-sparta-max-distance-allowed-for-location-verification-in-miles",
  WORKER_TO_WORKER_REFERRAL_ENABLED_BANNERS = "2023-12-sparta-enabled-worker-to-worker-referral-banners",
  SOLVE_UNPAID_BREAKS_ROLLOUT_STAGE_WITH_TARGETING = "2024-02-team-x-solve-worked-unpaid-breaks-rollout",
  HYPER_TRACK_TRIAL = "2024-01-hyper-track-trial",
  OPEN_SHIFT_CALENDAR_V2_CELL_VARIANTS = "2024-02-frontend-force-open-shift-calendar-cell-variants",
  HOME_HEALTH_ENABLED = "2024-02-home-health-HCP-enabled",
  ROLLOUT_QUIZ_WORKERS_ON_WORKPLACE_RULES = "2024-02-action-rollout-quiz-workers-on-workplace-rules",
  ROLLOUT_SHOW_CORRECT_ANSWERS_ON_QUIZ_SUBMISSION = "2024-02-action-rollout-show-correct-answers-on-quiz-submission",
  ROLLOUT_SHIFT_BLOCK_BOOKING = "2024-02-facilitate-rollout-block-shift-booking",
  HOME_HEALTH_BOOK_DIALOG_ATTENDANCE_ENABLED = "2024-04-home-health-hcp-book-dialog-attendance-enabled",
  STREAKS_ENABLED = "2024-04-core-services-streaks-hcp-enabled",
  HCP_STREAKS_REWARDS = "2024-04-core-services-streaks-hcp-rewards",
  UNDO_BOOKING_TIME_THRESHOLD = "2024-05-action-feature-undo-booking-time-threshold",
  UNDO_BOOKING_BUFFER_AFTER_BUTTON_CLICK_IN_SECONDS = "2024-07-action-feature-undo-booking-buffer-after-button-click-in-seconds",
  ACTIVATION_REFERRAL_SHARE_MESSAGE_CONFIG = "2024-03-sparta-activation_referral_share_message_config",
  SHOW_DEBUG_PAGE_MENU_ITEM_IN_MY_ACCOUNT = "2024-05-team-action-show-debug-page-menu-item-in-my-account",
  TIMELINESS_POINT_DEDUCTION_WARNING_ENABLED = "2024-05-invaluables-timeliness-point-deduction-warning-enabled",
  ATTENDANCE_SCORE_HISTORY_TABLE_ENABLED = "2024-06-invaluables-attendance-score-history-table-enabled",
  SHOW_WORK_SHIFT_REVAMP_FEEDBACK_MODAL = "2024-07-invaluables-rollout-revamp-work-shift-feedback-modal",
  PROOF_OF_EARNINGS_REVAMP = "2024-05-proof-of-earnings-revamp",
  HCP_SOFT_DELETION = "2024-06-sparta-rollout-hcp-soft-deletion",
  CHECK_ALWAYS_ALLOW_LOCATION_SETTING = "2024-06-value-check-always-allows-location-setting",
  HOME_HEALTH_VISIT_REQUIREMENT_CHECK = "2024-06-home-health-visit-requirement-check",
  HYPERTRACK_CLOCK_IN_OUT = "2024-07-value-hypertrack-clock-in-out",
  ALLOW_FACILITIES_TO_SET_MAX_CONSECUTIVE_HOURS = "2024-07-allow-facilities-to-set-max-consecutive-hours-and-hours",
  TWILIO_VERIFICATION_API = "2024-08-sparta-rollout-twilio-verification-api",
  SHOW_DEBIT_CARD_EXPIRATION_BANNER = "2024-08-team-x-expiring-debit-card-banner",
  PA_EXCLUSION_EXPERIMENT = "2024-08-core-services-pa-exclusion-experiment",
  NEW_CLOCK_OUT_MODAL = "2024-08-team-x-clock-out-modal-rollout",
  BIOMETRIC_QUALITY_SIGNAL_EXPERIMENT = "2024-08-pricing-rollout-biometric-quality-signal-experiment",
  GET_INSTANT_BOOK_SHIFTS_ENABLED = "2024-09-action-get-instant-book-shifts-enabled",
}
