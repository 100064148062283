import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Link } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type ContactSupportLinks } from "@src/appV2/support/types";

import { type Agent } from "../Agents";
import { SupportArticles, SupportContext } from "../support/constants";

export interface IcAgreementHelpTextProps {
  contactSupportLinks: ContactSupportLinks;
  /**
   * FIXME: A workaround for the Agent type incompatibility between V1 code and V2 code.
   * Agent type in V1 (src/lib/interface/src/lib/agent.ts) has all optional fields.
   * Partial here will make all fields optional, and since some fields
   * have more divergence, we're picking only utilized fields.
   */
  agent: Pick<Partial<Agent>, "userId">;
}

export function IcAgreementHelpText(props: IcAgreementHelpTextProps) {
  const {
    contactSupportLinks: { articleLink, howToContactSupportLink },
    agent: { userId },
  } = props;

  const isArticleLinkAvailable = isDefined(articleLink);
  const isContactSupportLinkAvailable = isDefined(howToContactSupportLink);

  if (!isDefined(userId) || (!isArticleLinkAvailable && !isContactSupportLinkAvailable)) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", paddingLeft: 0, flexWrap: "wrap" }}>
      <Text variant="body2" color="inherit" display="inline">
        Having trouble signing?
      </Text>

      {isArticleLinkAvailable && (
        <>
          &nbsp;
          <Text variant="body2" color="inherit" display="inline">
            Please check the
          </Text>
          &nbsp;
          <Link
            variant="body2"
            href={articleLink}
            target="_blank"
            sx={{
              color: "inherit",
              textDecorationColor: "inherit",
              ":hover": {
                color: "inherit",
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
                articleLink,
                articleName: SupportArticles.SIGN_IC_AGREEMENT,
                userId,
                supportContext: SupportContext.IC_AGREEMENT_ERROR_ALERT,
              });
            }}
          >
            <b>&ldquo;Unable to sign the IC Agreement&rdquo;</b>
          </Link>
          &nbsp;
          <Text variant="body2" color="inherit" display="inline">
            help article
          </Text>
          &nbsp;
        </>
      )}

      {isArticleLinkAvailable && isContactSupportLinkAvailable && (
        <>
          <Text variant="body2" color="inherit" display="inline">
            or
          </Text>
          &nbsp;
        </>
      )}

      {isContactSupportLinkAvailable && (
        <Link
          variant="body2"
          href={howToContactSupportLink}
          target="_blank"
          sx={{
            color: "inherit",
            textDecorationColor: "inherit",
            ":hover": {
              color: "inherit",
              textDecoration: "underline",
            },
          }}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
              articleLink: howToContactSupportLink,
              articleName: SupportArticles.HOW_TO_CONTACT_SUPPORT,
              userId,
              supportContext: SupportContext.IC_AGREEMENT_ERROR_ALERT,
            });
          }}
        >
          <b>contact support.</b>
        </Link>
      )}
    </Box>
  );
}
